import * as React from "react";
import { Step, TooltipRenderProps } from "react-joyride";

import { Button } from "~/components/core/Button";
import { noop } from "~/utils/noop";

interface Props extends TooltipRenderProps {
  continuous: boolean;
  index: number;
  isLastStep: boolean;
  size: number;
  step: Step;
}

const DashboardGuideStep: React.FC<Props> = ({
  primaryProps,
  skipProps,
  tooltipProps,
  index,
  step,
  size,
  isLastStep
}) => (
  <div
    className="paragraph bg-white rounded-[4px] max-w-[420px] min-w-[290px] relative p-[16px]"
    {...tooltipProps}
  >
    {`${index + 1}/${size}`}
    <div className="text-[20px] font-semibold">{step.title}</div>
    <div className="paragraph mt-[10px]">{step.content}</div>
    <div className="flex gap-[16px] justify-end mt-[32px]">
      {!isLastStep && (
        <Button variant="secondaryGray" action={noop} {...skipProps}>
          Skip tutorial
        </Button>
      )}
      <Button variant="primary" action={noop} {...primaryProps}>
        {isLastStep ? "Done" : "Next"}
      </Button>
    </div>
  </div>
);

export default DashboardGuideStep;
