import React from "react";

interface Props {
  color: string;
  text: string;
}

const LegendItem: React.FC<Props> = ({ color, text }: Props): JSX.Element => {
  return (
    <>
      <div
        className="h-[8px] w-[8px] ml-[20px] rounded-full"
        style={{ backgroundColor: color }}
      />
      <div className="explanatoryText text-center ml-[10px]">{text}</div>
    </>
  );
};

export default LegendItem;
