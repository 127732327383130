import ErrorIcon from "@material-design-icons/svg/filled/error.svg";
import Skeleton from "@material-ui/lab/Skeleton";
import * as React from "react";

const StatisticCardSkeleton = React.forwardRef<
  HTMLDivElement,
  { error?: boolean }
>(({ error }, ref) => {
  return (
    <div className="flex justify-center flex-1" ref={ref}>
      <Skeleton
        className="transform-none rounded-[8px] bg-cream-300 flex items-center justify-center"
        width="100%"
        height={170}
        animation={error ? false : "pulse"}
      >
        {error && <SkeletonError />}
      </Skeleton>
    </div>
  );
});

export default StatisticCardSkeleton;

export const SkeletonError: React.FC<{ isChart?: boolean }> = ({ isChart }) => {
  return (
    <div className="flex items-center gap-[8px] explanatoryText !text-redApple-700 whitespace-pre-wrap">
      <ErrorIcon
        aria-hidden
        className="w-[24px] h-[24px] flex-shrink-0 fill-current"
      />
      {isChart
        ? `Something went wrong and we couldn't load this chart.\nPlease try reloading the page.`
        : `We couldn't load this stat.\nPlease try reloading the page.`}
    </div>
  );
};
