import React from "react";

import LegendItem from "./LegendItem";

export interface LegendDataModel {
  color: string;
  text: string;
}

const Legend: React.FC<{ legendData: LegendDataModel[] }> = ({
  legendData
}): JSX.Element => {
  return (
    <div className="flex items-center justify-end mr-[20px]">
      {legendData &&
        legendData.map(({ color, text }: LegendDataModel) => (
          <LegendItem key={color} color={color} text={text} />
        ))}
    </div>
  );
};

export default Legend;
