import { omit } from "ramda";
import * as React from "react";

import Rating from "~/components/core/Rating/Rating";
import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";

import { createDashboardLazyWidget } from "../createDashboardLazyWidget";
import DashboardContext from "../DashboardContext";

const DashboardPowerUsersSessions = createDashboardLazyWidget({
  displayName: "DashboardPowerUsersSessions",
  className: "flex-1 p-[24px] max-h-[325px]",
  contentHeight: 270,
  useRender: (apiQuery, resetFilters) => {
    const { selectedPeriod } = React.useContext(DashboardContext);

    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/new_stats/combined/power_sessions/",
      omit(["scale"], apiQuery),
      true
    );

    React.useEffect(() => {
      trackEvent("load_power_users_sessions", undefined);
    }, []);
    return (
      <WidgetContentNew
        loading={loading}
        hasData={!!data?.length}
        error={error}
        resetFilters={resetFilters}
      >
        <div className="flex gap-[16px] items-center mb-[16px]">
          <div className="h2 line-clamp-1 max-w-[100%]">
            Power users - Sessions
          </div>
          <div className="!text-gray-600 explanatoryText">
            {selectedPeriod?.title || ""}
          </div>
        </div>

        <table className="w-full table-auto">
          <thead>
            <tr className="fieldLabel tracking-[.45px] text-left border-b border-cream-300">
              <th>Student</th>
              <th className="text-right pr-[60px]">Sessions</th>
              <th>Avg Rating</th>
            </tr>
          </thead>
          <tbody className="text-left normal-case align-bottom paragraph">
            {data?.map(user => (
              <tr key={user.userId} className="h-[40px]">
                <td className="truncate max-w-[100%]">{user.displayName}</td>
                <td className="text-right  pr-[60px]">{user.sessionsTaken}</td>
                <td className="w-[150px]">
                  <Rating rating={user.averageStudentRating} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </WidgetContentNew>
    );
  }
});

export default DashboardPowerUsersSessions;
