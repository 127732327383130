import * as React from "react";

import Select from "~/components/core/Select";
import { DropDownFilterItem, SESSION_VARIANTS } from "~/constants/filters";

interface Props {
  onChange: (value: string) => void;
  selectedOption: DropDownFilterItem;
}

const SelectVariants: React.FC<Props> = ({ onChange, selectedOption }) => {
  return (
    <Select
      label={pendingLabel}
      options={SESSION_VARIANTS}
      onChange={onChange}
      selectedOption={selectedOption}
      className="w-[215px] pendingSessionsSelect"
      clarification={clarification}
    />
  );
};

export default SelectVariants;

const pendingLabel = (
  <>
    Include pending<span className="hideLabel"> sessions</span>
  </>
);

const clarification = (
  <>
    <div className="mb-[12px]">
      All sessions are moderated or reviewed by Pear Deck tutor staff within
      7-10 business days.
    </div>
    <div>
      <span className="font-semibold">Pending sessions</span> have not yet been
      moderated or reviewed. Not including pending sessions may show fewer
      sessions than have actually been completed.
    </div>
  </>
);
