import React from "react";
import { Step } from "react-joyride";

import DashboardGuideContext from "~/components/dashboard/DashboardGuide/DashboardGuideContext";

export function useDashboardGuideSteps(): Step[] {
  const {
    headerRef,
    filtersRef,
    generalReportsRef,
    liveLessonsRef,
    writingLabRef
  } = React.useContext(DashboardGuideContext);

  return [
    {
      content: `These are your 3 main reports that shows how many students are enrolled, total sessions completed, and total minutes used. They cannot be filtered.`,
      target: headerRef?.current || "body",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      placement: "bottom-start",
      spotlightClicks: true,
      spotlightPadding: 5,
      styles: {
        options: {
          zIndex: 9999
        }
      },
      title: "Static Reports"
    },
    {
      content: `These are your filters that you can use to narrow down any reports you might like to see. Everything below these filters will be affected accordingly.`,
      target: filtersRef?.current || "body",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: true,
      spotlightPadding: 5,
      styles: {
        options: {
          zIndex: 9999
        }
      },
      title: "Filters"
    },
    {
      content: `These reports are the sum of all the sessions: hours, students, sessions, ratings, and power users.`,
      target: generalReportsRef?.current || "body",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: true,
      spotlightPadding: 0,
      styles: {
        options: {
          zIndex: 9999
        }
      },
      title: "General usage"
    },
    {
      content: `These are the reports for live tutoring lessons, both for desktop and mobile.`,
      target: liveLessonsRef?.current || "body",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "bottom-end",
      spotlightClicks: true,
      spotlightPadding: 0,
      styles: {
        options: {
          zIndex: 9999
        }
      },
      title: "Live Lessons"
    },
    {
      content: `These are the reports for Writing Lab.`,
      target: writingLabRef?.current || "body",
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: true,
      spotlightPadding: 0,
      styles: {
        options: {
          zIndex: 9999
        }
      },
      title: "Writing Lab"
    }
  ];
}
