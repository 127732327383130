import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import React from "react";

import WidgetCardContent from "~/components/core/WidgetCard/WidgetCardContent";
import DashboardContext from "~/components/dashboard/DashboardContext";
import { REQUEST_STATE } from "~/constants/apiInteraction";
import { isString } from "~/utils/common";

import ClarificationIcon from "../ClarificationIcon";

export interface WidgetCardProps {
  id?: string;
  caption?: string;
  headerEndAdornment?: React.ReactNode;
  hasData?: boolean;
  clarification?: string;
  contentHeight?: number | string;
  requestState?: REQUEST_STATE;
  className?: string;
  wrapContent?: boolean;
  children?: React.ReactNode;
  resetFilters?: () => void;
}

const WidgetCard = React.forwardRef<HTMLElement, WidgetCardProps>(
  (
    {
      id,
      caption,
      clarification,
      headerEndAdornment,
      contentHeight,
      children,
      requestState,
      className,
      hasData = false,
      wrapContent = true,
      resetFilters
    },
    ref
  ) => {
    const { selectedPeriod } = React.useContext(DashboardContext);
    const selectedPeriodTitle = selectedPeriod?.title || "";

    const height =
      contentHeight === undefined
        ? "auto"
        : isString(contentHeight)
        ? contentHeight
        : `${contentHeight}px`;
    return (
      <Card
        id={id || ""}
        ref={ref}
        elevation={0}
        className={classnames(
          className,
          "flex flex-col rounded-[8px] border-2 border-cream-300"
        )}
      >
        {caption && (
          <div className="w-full flex flex-row justify-between px-[32px] py-[16px]">
            <div className="flex gap-[16px] items-center">
              <div className="h2 line-clamp-1 max-w-[100%]">{caption}</div>
              <Typography
                className="!text-gray-600 explanatoryText"
                variant="body2"
              >
                {selectedPeriodTitle}
              </Typography>
            </div>
            {headerEndAdornment}
            <ClarificationIcon
              clarification={clarification}
              className="mt-[1px]"
            />
          </div>
        )}

        <div className="overflow-y-auto" style={{ height }}>
          {wrapContent ? (
            <WidgetCardContent
              requestState={requestState}
              hasData={hasData}
              resetFilters={resetFilters}
            >
              {children}
            </WidgetCardContent>
          ) : (
            children
          )}
        </div>
      </Card>
    );
  }
);

export default WidgetCard;
