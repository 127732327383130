import { Skeleton } from "@material-ui/lab";
import React from "react";

import { PERIOD } from "~/constants/dates";
import { SCALE, SESSION } from "~/constants/filters";
import { DashboardApiQuery } from "~/utils/http";

import WidgetCard from "../core/WidgetCard/WidgetCard";
import { skeletonClasses } from "../core/WidgetCard/WidgetCardContent";
import {
  useDashboardLazy,
  useDashboardQueryState
} from "./dashboardQueryState";

export type CreateDashboardLazyWidgetProps = {
  displayName: string;
  clarification?: string;
  caption?: string;
  contentHeight?: number;
  useRender: (
    apiQuery: DashboardApiQuery,
    resetFilters: () => void
  ) => React.ReactElement | null;
  headerEndAdornment?: React.ReactNode;
  className?: string;
};

export function createDashboardLazyWidget({
  displayName,
  clarification,
  caption,
  contentHeight,
  useRender,
  headerEndAdornment,
  className
}: CreateDashboardLazyWidgetProps) {
  const Lazy: React.FC = function Lazy() {
    const [, setQueryState] = useDashboardQueryState();
    const { showContent, apiQuery, ref } = useDashboardLazy(displayName);

    const resetFilters = () => {
      setQueryState({
        subject: null,
        period: PERIOD.last30Days,
        scale: SCALE.daily,
        moderation: SESSION.all,
        fromDt: undefined,
        tillDt: undefined
      });
    };

    const Comp: React.FC<{
      apiQuery: DashboardApiQuery;
      resetFilters: () => void;
    }> = ({ apiQuery, resetFilters }) => {
      return useRender(apiQuery, resetFilters);
    };

    Comp.displayName = displayName;

    return (
      <WidgetCard
        contentHeight={contentHeight}
        caption={caption}
        clarification={clarification}
        headerEndAdornment={headerEndAdornment}
        ref={ref}
        wrapContent={false}
        resetFilters={resetFilters}
        className={className}
      >
        {showContent ? (
          <Comp apiQuery={apiQuery} resetFilters={resetFilters} />
        ) : (
          <Skeleton className={skeletonClasses} />
        )}
      </WidgetCard>
    );
  };

  return Lazy;
}
