import Typography from "@material-ui/core/Typography/Typography";
import * as React from "react";

const SectionTitle: React.FC<{ title: string; subtitle?: string }> = ({
  title,
  subtitle
}): JSX.Element => {
  return (
    <div>
      <Typography className="h1" variant="h4">
        {title}
      </Typography>
      {subtitle && (
        <Typography className="paragraph mt-[4px]" variant="subtitle1">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

export default SectionTitle;
