import ArrowIcon from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import classNames from "classnames";
import * as React from "react";

import { Button, ButtonLink } from "~/components/core/Button";
import ROUTES from "~/constants/routes";
import { ISubjectStatData } from "~/declarations/models/Subject";
import { noop } from "~/utils/noop";

import DashboardContext from "../DashboardContext";

interface ISubjectStatsRow {
  data: ISubjectStatData;
  level?: 2 | 3;
  handleSubjOpen: (subjName: string) => void;
  isOpen?: boolean;
  handleGrade: (value: string) => void;
}

export const SubjectStatsRow: React.FC<ISubjectStatsRow> = ({
  data,
  level,
  handleSubjOpen,
  isOpen,
  handleGrade
}) => {
  // const { selectedUmbrella } = useRequiredAuthContext();
  return (
    <tr>
      <td
        className={classNames(
          "text-left pt-[16px]",
          level === 2 && "pl-[32px]",
          level === 3 && "pl-[64px]"
        )}
      >
        {data.children.length > 0 ? (
          <Button
            action={() => handleSubjOpen(data.subject)}
            variant="wrapper"
            className="!flex items-center hover:link"
          >
            <ArrowIcon
              className={classNames(
                "w-[24px] h-[24px] flex-shrink-0 transition-transform ease-in-out rotate-[-90deg]",
                isOpen && "rotate-0"
              )}
              aria-hidden
            />
            {data.subject}
          </Button>
        ) : (
          <div className="ml-[24px]">{data.subject}</div>
        )}
      </td>
      {/* {selectedUmbrella.partnerType === "k-12" && */}
      <td className="pt-[16px]">
        <div className="flex gap-[4px] justify-end">
          {data.gradeLevels
            .filter(grade => grade !== null)
            .sort((a, b) => a - b)
            .map(grade => (
              <Button
                key={grade}
                action={() => handleGrade(grade.toString())}
                variant="wrapper"
                className="rounded-[8px] bg-blueSkies-300 hover:bg-blueSkies-400 active:bg-blueSkies-500 px-[8px] py-[4px]"
              >
                {grade === 0 ? "K" : grade}
              </Button>
            ))}
        </div>
      </td>
      <td className="pt-[16px]">{data.students}</td>
      <td className="pt-[16px] ">
        <ButtonLink
          variant="wrapper"
          className="paragraph link"
          href={ROUTES.lessons}
          action={noop}
        >
          {data.sessions}
        </ButtonLink>
      </td>
      <td className="pt-[16px]">{Math.round(data.minutes).toLocaleString()}</td>
      <td className="pt-[16px] font-bold">
        {Math.round(data.percentOfTotalMinutes)}%
      </td>
    </tr>
  );
};

export const TableHeader: React.FC = () => {
  // const {selectedUmbrella } = useRequiredAuthContext();
  return (
    <thead>
      <tr className="h-[42px] fieldLabel tracking-[.45px] text-right border-b border-cream-300 align-bottom">
        <th className="text-left">Subject</th>
        {/* {selectedUmbrella.partnerType === "k-12" && <th>Session grade level</th>} */}
        <th>
          Session
          <br /> grade level
        </th>
        <th>
          Unique
          <br /> students
        </th>
        <th>Sessions</th>
        <th>Minutes</th>
        <th>
          % of total
          <br /> minutes
        </th>
      </tr>
    </thead>
  );
};

export const SubjectStatsHeader: React.FC = () => {
  const { selectedPeriod } = React.useContext(DashboardContext);
  return (
    <div className="flex flex-row items-center justify-between w-full">
      <div className="flex gap-[16px] items-center">
        <div className="h2 line-clamp-1 max-w-[100%]">
          Live tutoring subjects
        </div>
        <div className="!text-gray-600 explanatoryText">
          {selectedPeriod?.title || ""}
        </div>
      </div>
      <ButtonLink
        variant="wrapper"
        className="paragraph link"
        href={ROUTES.lessons}
        action={noop}
      >
        View all sessions
      </ButtonLink>
    </div>
  );
};
