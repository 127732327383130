import React from "react";

import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";

import { DashboardAreaChart } from "../AreaChart";
import { createDashboardLazyWidget } from "../createDashboardLazyWidget";

const DashboardWritingLabPapersCompletedChart = createDashboardLazyWidget({
  displayName: "DashboardWritingLabPapersCompletedChart",
  contentHeight: 310,
  caption: "Papers completed",
  clarification:
    "Number of papers reviewed by tutors during the time frame selected.",
  useRender: (apiQuery, resetFilters) => {
    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/charts/wl_sessions/",
      apiQuery,
      true
    );
    React.useEffect(() => trackEvent("wl_load_completed_chart", undefined), []);
    return (
      <WidgetContentNew
        loading={loading}
        hasData={!!data?.length}
        error={error}
        resetFilters={resetFilters}
      >
        <DashboardAreaChart apiQuery={apiQuery} data={data} label="Papers" />
      </WidgetContentNew>
    );
  }
});

export default DashboardWritingLabPapersCompletedChart;
