import React from "react";

import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";

import { DashboardAreaChart } from "../AreaChart";
import { createDashboardLazyWidget } from "../createDashboardLazyWidget";

const DashboardWritingLabHoursChart = createDashboardLazyWidget({
  displayName: "DashboardWritingLabHoursChart",
  contentHeight: 310,
  caption: "Writing Lab hours",
  clarification:
    "Number of hours used by students for paper reviews during the time frame selected.",
  useRender: (apiQuery, resetFilters) => {
    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/charts/wl_hours/",
      apiQuery,
      true
    );
    React.useEffect(() => trackEvent("wl_load_hours_chart", undefined), []);
    return (
      <WidgetContentNew
        loading={loading}
        hasData={!!data?.length}
        error={error}
        resetFilters={resetFilters}
      >
        <DashboardAreaChart apiQuery={apiQuery} data={data} label="Hours" />
      </WidgetContentNew>
    );
  }
});

export default DashboardWritingLabHoursChart;
