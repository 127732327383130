import React from "react";

import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";

import { DashboardAreaChart } from "../AreaChart";
import { createDashboardLazyWidget } from "../createDashboardLazyWidget";

const DashboardActiveStudentsChart = createDashboardLazyWidget({
  displayName: "DashboardActiveStudentsChart",
  contentHeight: 310,
  caption: "Active students",
  clarification:
    "Number of students who used Pear Deck Tutor during the time frame selected.",
  useRender: (apiQuery, resetFilters) => {
    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/charts/active_students/",
      apiQuery,
      true
    );
    React.useEffect(() => {
      trackEvent("load_active_students_chart", undefined);
    }, []);
    return (
      <WidgetContentNew
        loading={loading}
        hasData={!!data?.length}
        error={error}
        resetFilters={resetFilters}
      >
        <DashboardAreaChart apiQuery={apiQuery} data={data} label="Students" />
      </WidgetContentNew>
    );
  }
});

export default DashboardActiveStudentsChart;
