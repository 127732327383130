import { SubjectGroup } from "~/declarations/models/Subject";

export const findParent = (
  subjectID: number,
  subjects: SubjectGroup[],
  parent?: SubjectGroup
): undefined | { subj: SubjectGroup; parent: SubjectGroup | "all" } => {
  for (const subj of subjects) {
    if (subj.id === subjectID) {
      return { parent: parent ? parent : "all", subj };
    }
    if (subj.children) {
      const foundSubj = findParent(subjectID, subj.children, subj);
      if (foundSubj) {
        return foundSubj;
      }
    }
  }
  return undefined;
};

export const findSubjectPath = (
  subjects: SubjectGroup[],
  subjectId: number,
  currentPath?: number[]
): number[] | null => {
  let done = false;
  let path: number[] = currentPath ?? [];

  for (let i = 0; i < subjects.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const subject = subjects[i]!;
    if (subject.id === subjectId) {
      path.push(subject.id);
      done = true;
      break;
    } else {
      const foundInChildren = findSubjectPath(
        subject.children ?? [],
        subjectId,
        [...path, subject.id]
      );
      if (foundInChildren) {
        path = foundInChildren;
        done = true;
        break;
      }
    }
  }
  return done ? path : null;
};
